export default {
  server: 'wss://router.equery.cherg.net/',
  another_server: 'https://s1.main.ru.cherg.net/',
  servid: '540',
  servname: 'DMSU TEST',
  wait_time: { // конфиг таймаутов
    current_queue: 5000, // текущая очередь
    current_client: 5000,
    deferred_queue: 5000,
    waiting_list: 5000,
    count_message: 0,
    message: 0,
    reg_list: 0,
    intercom_list: 0,
    server_time: 1000,
    auto_time: 1000,
    beep_tab: 1000
  },
  registration_tab: true,
  waiting_tab: true,
  graf_tab: true,
  plan_time: true,
  viber_chat: false,
  intercom_chat: false,
  lang: 'ua',
  additional_services_time: [
    {
      _name: '5 хв',
      _time: '300'
    },
    {
      _name: '10 хв',
      _time: '600'
    },
    {
      _name: '15 хв',
      _time: '900'
    },
    {
      _name: '20 хв',
      _time: '1200'
    }
  ]
}
